.myPanel {
	width: 100%;
	border: 1px #ced4da solid;
	padding: 0.375rem 0.75rem;
	background-color: #e9ecef;
	border-radius: 0.25rem;
	margin-bottom: 0.25rem;
}

.headingWrapper {
	margin: -0.375rem -0.75rem;
	background-color: #b8daff;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.headingWrapperBright {
	margin: -0.375rem -0.75rem;
	background-color: #00bbff29;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.collapsible:hover {
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	background-color: #4598f3;
	cursor: pointer;
}

.panelHeading {
	padding: 0.375rem 0.75rem;
}

.main {
	background-color: #7ebafb;
	font-weight: 500;
}

.panelBody {
	display: block;
	padding: 0.875rem 0rem 0.1rem;
}

.infoLabel {
	display: inline-block;
	padding: 0.2em 0.6em 0.3em;
	line-height: 1.5;
	color: #000;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25em;
	background-color: #b8daff;
	margin-right: 0.25rem;
	border: 1px solid #ced4da;
}

.textareaWrapper {
	margin-top: 0.5rem;
	width: 100%;
}

textarea {
	width: 100%;
	padding: 0.3rem 0.5rem;
	border-radius: 0.25em;
}

.noteWrapper {
	margin-top: 0.5rem;
	width: 100%;
}

.collapsed {
	display: none;
}

.unsaved {
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	background-color: #cbecff;
}

.unsaved:hover {
	background-color: #99daff;
}

::placeholder {
	color: #aaa;
}

.has-error {
	border: 1px solid red !important;
	background-color: rgba(255, 0, 0, 0.03) !important;
}

/*loading spinner*/
.lds-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.25);
	z-index: 10000;
	display: block;
}

.lds-ripple {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 64px;
	height: 64px;
}
.lds-ripple div {
	position: absolute;
	border: 4px solid #fff;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
	animation-delay: -0.5s;
}
@keyframes lds-ripple {
	0% {
		top: 28px;
		left: 28px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: -1px;
		left: -1px;
		width: 58px;
		height: 58px;
		opacity: 0;
	}
}
