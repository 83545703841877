.fullWidth {
  width: 100%;
}

@media only screen and (max-width: 1799px) {
  .leadsList {
    width: 100%;
  }

  .leadForm {
    width: 100%;
  }
}

@media only screen and (min-width: 1800px) {
  .leadsList {
    min-width: 1100px;
    float: left;
  }

  .leadForm {
    width: 100%;
    max-width: 2000px;
  }
}
