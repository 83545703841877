/*
  scrollbar
*/
.myScrollbar::-webkit-scrollbar-track {
	background-color: #f5f5f5;
}

.myScrollbar::-webkit-scrollbar {
	width: 10px;
	background-color: #f5f5f5;
}

.myScrollbar::-webkit-scrollbar-thumb {
	background-color: #007bff;
}

/*
  table
*/

.wrapper {
	display: flex;
	overflow-y: hidden;
	padding: 0.25rem;
}

.header {
	border: 1px solid #dfdfdf;
	background-color: #007bff;
	color: white;
	margin: -1px;
	padding: 0.5rem;
	white-space: nowrap;
}

.cell {
	border: 1px solid #dfdfdf;
	margin: -1px;
	padding: 0.5rem;
	white-space: nowrap;
	cursor: pointer;
}
